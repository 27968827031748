<template>
  <v-app id="app">
    <v-app-bar
      app
      color="white"
      elevation="2"
    >

      <v-img       
        max-width="150"
        src="@/assets/flakest_logo.jpg"
        @click="reloadHome();"
        style="cursor: pointer"
      ></v-img>

      <v-spacer/>

         <v-btn        
          color="blue-grey"
          class="ma-2 white--text" 
          @click="createModal()"       
        >
         <v-icon
            dark
            small
          >
            mdi-auto-fix
          </v-icon>
           Create New
         
    </v-btn>

    </v-app-bar>

   <router-view/>

   <v-dialog
        v-model="creatingPost"
        activator="parent"
        width=auto
      >
        <v-card>
          <v-card-title>
             <v-progress-circular
                  indeterminate
                  color="brown"
                  :size="40"
                >💩</v-progress-circular>
            Creating Post...
          </v-card-title>
          <v-card-text>
            <v-row>
              <br/>
            </v-row>
            <v-row>
                ⌛Hold tight! We're generating a new post... <br/><br/>
                ☝️FYI: this may take upto a minute!
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="creatingPost = false">Close </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="createPost"
        activator="parent"
        width="auto"
      >
        <v-card>
          <v-card-title>           
            💩 Creat A Post...
          </v-card-title>
          <v-card-text>

              <p>Enter a prompt below, or leave blank to let the bot choose...</p>
              <v-textarea
              label="eg/ A local hairdresser finds gold in rectum."           
              outlined
              name="input-7-4"
              v-model="postPrompt"
            ></v-textarea>

       
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="green" @click="createNewPost()">
              <v-icon
                dark
                small
              >
                mdi-auto-fix
              </v-icon>
              Create </v-btn>

            <v-btn color="grey" @click="createPost = false">
              <v-icon
                dark
                small
              >
                mdi-close-box-outline
              </v-icon>
              Close </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

     <v-snackbar v-model="problem" :multi-line="true" > 
      <span v-html="problemText"></span>
        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="problem = false">
            Close
            </v-btn>
        </template>
    </v-snackbar>   

  </v-app>
</template>





<script>
import dataService from './services/dataService.js';


export default {
  name: 'App',
  
  data: () => ({
    createPost: false,
    creatingPost: false,
    problem: false,
    problemText: null,
    postPrompt: '',
    something: '',
  }),
  methods: {
       async createModal(){
          this.createPost = true;
       },
       async createNewPost(){
        //will create 1 new post and refresh page.
          try {

            //console.log(this.postPrompt);
            this.createPost = false;
            this.creatingPost = true; //add 'prompt: ''' to body to gen a particula thing.
            let prompt={postId: '1'}; //just a null prop
            if(this.postPrompt!=''){prompt={'prompt': this.postPrompt}}

            let response =  await dataService.apiPost('/createAutoPost',prompt)
            console.log(response);
            //this.$router.go('/'); //refresh router page back to top.
            this.$router.go('/');
            this.createPost = false;
          } catch (error) {
            this.createPost = false;
            this.problem = true;
            this.problemText = `Ooops, looks like we flaked out: ${error}`
          }
       },
       async reloadHome(){
        console.log('routed to home...')
        this.$router.push('/');   
       }
  }
};
</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
#app {
  /* background: url('./assets/splash_bkg_beta.jpg') no-repeat center center fixed;  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;  */
  /*background-color: #242939!important;*/
  font-family: 'Quicksand', sans-serif;
  }
.footer {
  font-family: 'Quicksand', sans-serif;
  font-size: 9pt;
}
.welcome {
  font-family: 'Quicksand', sans-serif;
  font-size: 10pt;
  background-color: #f5f5f5
}


</style>
