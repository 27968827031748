<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >

      <span >

        <!-- <v-skeleton-loader
          v-bind="skeleton"
          type="image, article, actions"
        ></v-skeleton-loader> -->

         <v-card v-for="(post, index) in posts" :key="index"
            class="mx-auto postCard"
            max-width="100%"
          >
            

            <v-card-title 
            style="word-break: break-word"
            @click="showPostNewTab(post)"
            >
            {{post.title}}
            </v-card-title>

            <!-- <v-card-subtitle>
            {{content}}
            </v-card-subtitle> -->

             <v-card-text>

               <v-img
                :src=post.imageURL
                min-height="256px"
                min-width="256px"
                @click="showPostNewTab(post)"
                style="cursor: pointer"
                @error="post.imageURL='https://api.flakest.app/post_images/sad_poop.png'"
                >
                  <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
                </v-img>

                <!-- <span>{{post.content}}</span> // keep this for the post page-->

             </v-card-text>

            <v-card-actions style="padding: 25px">

              <v-icon class="mr-1" color=red @click="likePost(post)">
                mdi-heart
              </v-icon>
              <span class="subheading mr-2">{{post.voteCount}}</span>

              <span class="mr-1">·</span>

              <v-icon class="mr-1" color=blue @click="copyURL(post)">
                mdi-share-variant
              </v-icon>
              <!-- <span class="subheading">{{post.shareCount}}</span> -->

              <v-spacer/>

              <s-facebook             
              :share-options=post.shareOptions          
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                  </svg>
              </s-facebook>

              <span class="mr-1">·</span>
              
              <s-twitter
                :share-options=post.shareOptions 
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                  </svg>
                </s-twitter>

                <span class="mr-1">·</span>
              
              <s-reddit
                :share-options=post.shareOptions 
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                    <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                  </svg>
                </s-reddit>

            </v-card-actions>
 
         </v-card>

          <!--Add here the vuetify directive -->
          <v-card v-intersect.quiet="loadNext"></v-card>

      </span>
        

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataService from '../services/dataService.js';
import { SFacebook, STwitter, SReddit } from 'vue-socials';

export default {
    name: 'postCard',
    props: ['postId'],
    components: { SFacebook, STwitter, SReddit },
    data: () => ({
       skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 2,
      },
      posts: [],
      currentPostId: null,
      loading: false,
      loadError: false,
      
      //  windowFeatures: {},
      //   shareOptions: {
      //     url: 'https://github.com/',
      //     quote: 'Quote',
      //     hashtag: '#Github',
      //   },
      //   useNativeBehavior: false,
      
    }),
    methods: {   
        async loadPost(postId){     
          if(this.loadError==false){   
            try {
                let newPostObj = await dataService.loadPost(postId);
                //add a url to the object:
                
                  let desc = await this.swapSpacesWithHyphens(newPostObj.title);
                  newPostObj.link = (`https://flakest.app/#/p/${newPostObj.id}/d/${desc}`);
                //add share options:
                newPostObj.shareOptions = {
                  url: newPostObj.link, 
                  quote: newPostObj.title,
                  title: newPostObj.title,
                  text: newPostObj.title,
                  hashtag: '#flakest',
                  hashtags: ['flakest']}

                this.posts.push(newPostObj);

                this.currentPostId = newPostObj.id;

            } catch (error) {
                // this.$emit('showProblem','Post loading got flakey...');
                // let newPostObj = [{
                // title: `Ooop, flaked out... :(`,
                // imageURL: 'https://api.flakest.app/post_images/sad_poop.png'
                // }]
                // this.posts.push(newPostObj)
                //this.loadError = true;
                console.error(error);
            }
          }
        },
        async loadNext(){
          if(this.loading==false){ //seems to be triggering multiple times.
            this.loading = true;
            let amountToLoad = 5;
            for(let i = 1; i < amountToLoad; i++){
              if(this.currentPostId==1){return}
              this.currentPostId-=1
              await this.loadPost(this.currentPostId)
            }
            this.loading = false;
        }},
        async likePost(postObj){
         try {
              let newVoteCount = await dataService.likePost(postObj.id)            
              postObj.voteCount = newVoteCount.voteCount;
            } catch (error) {
              this.$emit('showProblem',error);
                console.error(error);
            }
        },
        async showPost(post){
          if(this.loadError==false){ 
            let desc = await this.swapSpacesWithHyphens(post.title);
            this.$router.push(`#/p/${post.id}/d/${desc}`); 
          }   
        },
        async showPostNewTab(post){ 
          if(this.loadError==false){ 
            let desc = await this.swapSpacesWithHyphens(post.title);
            let url = (`https://flakest.app/#/p/${post.id}/d/${desc}`); 
            window.open(url, '_blank', 'noreferrer');
          }   
        },
        async copyURL(post) {
          console.log(post);
          try {
              await navigator.clipboard.writeText(post.link);
              // console.log('Copied to clipboard!');
              this.$emit('showNotification','Copied URL to clipboard. :)');
          } catch (error) {
              console.error('Failed to copy: ', error);
             this.$emit('showProblem',error);
          }
        },
        async swapSpacesWithHyphens(str) {
          return str.replace(/\s+/g, '-');
        },
        async set404Img(event) { 
            event.target.src = "https://api.flakest.app/post_images/sad_poop.png" 
        }    
    },
    async mounted() {
      //load a group of 5 on mount ?
        await this.loadPost();
        await this.loadNext();
    }
}
</script>

<style scoped>
.postCard {
  margin-bottom: 20px; 
  background-color: #f5f5f5
}
  
</style>