let mode = 'remote' // set this to local for local testing.
//We're using the native js fetch now.
import store from '../store'; //import our state to our class.

let localEndPoint = 'http://localhost:3000';
let remoteEndPoint = 'https://api.flakest.app';
let apiEndPoint = ''
switch (mode){
    case 'local':
        apiEndPoint = localEndPoint;
         break;
    case 'remote':
        apiEndPoint = remoteEndPoint;
        break;
}

//set fetchOptions here, run after hoisted as needs access to vuex state.
const setFetchOptions = (state) => {
    if (state.userToken){  
        let Options = 
        { 
            mode: 'cors', 
            // withCredentials: true,
            // credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + state.userToken, 
                'Content-Type': 'application/json',
                'cache': 'no-cache', 
          }
        }
        return Options
    }else{
        let Options = {
            headers: { 
            'cache': 'no-cache',
            'Content-Type': 'application/json'
          }
        }
        return Options
    }
    }
    
    
    export default {
    
        async apiGet(endPoint){ 
            try {
                let Options = setFetchOptions(store.state);       
                Options.method = 'GET'; //add method key 
                //console.log(Options);
                let response = await fetch(apiEndPoint+`${endPoint}`,Options) //return fetch promise
                if (!response.ok) {
                    throw new Error(`${response.status}`); //this may need to pass more descriptive data.
                }     
            let responseJson = await response.json();
            //console.log(responseJson);
            return responseJson;
            } catch (error) {
                throw new Error(error)
                // Handle any errors that occur during the fetch
            }
        },
    
        async apiPost(endPoint, body){
           try {
            let Options = setFetchOptions(store.state); 
            Options.method = 'POST'; //add method key
            Options.body = JSON.stringify(body); //add post body:
            //console.log(Options);
            let response = await fetch(apiEndPoint+`${endPoint}`,Options) //this may need to pass more descriptive data.
                if (!response.ok) {
                    throw new Error(`${response.status}`);
                }
            let responseJson = await response.json();
            //console.log(responseJson);
            return responseJson;
        } catch (error) {
            throw new Error(error)
            // Handle any errors that occur during the fetch
        }
        },

        //Helpers:

        async loadPost(postId){

            let endPoint = '/getPost';
            
            if(postId)endPoint+=`?postId=${postId}` 

             try {
               let postObj = await this.apiGet(endPoint);

                let newPostObj = {
                  id: postObj.id,
                  title:  postObj.title,
                  imageURL: `https://api.flakest.app/post_images/${postObj.id}.jpg`,
                  content: postObj.content, 
                  voteCount: postObj.voteCount,
                  shareCount: postObj.shareCount
                }

                if(!newPostObj.voteCount){newPostObj.voteCount=0}
                if(!newPostObj.shareCount){newPostObj.shareCount=0}

                return(newPostObj)
       

            } catch (error) {
                throw new Error(error)
                // Handle any errors that occur during the fetch
            }

        },

        async likePost(postId){

            let endPoint = `/likePost`; //change URL in prod
            let body = {id: postId};

             try {
                                 
                  const responseData = await this.apiPost(endPoint,body)
                  return responseData
    
                } catch (error) {
                  
                    throw new Error(error)
                  
                }
            }
    }