<template>
   <div>
    <v-row class="justify-center align-center">
    <v-col
      cols="12"
      lg="8"
      md="8"
      sm="8"

      
    >
      <v-sheet
        min-height="70vh" 
        rounded="lg"
        style="margin-top: 10px"
        
      >
         <v-card
            class="mx-auto post"
            max-width="800px"
          
          >
            

            <v-card-title style="word-break: break-word">
            {{postData.title}}
            </v-card-title>

            <!-- <v-card-subtitle>
            {{content}}
            </v-card-subtitle> -->

             <v-card-text>

               <v-img
                :src=postData.imageURL
                min-height="256px"
                min-width="256px"
                @error="postData.imageURL='https://api.flakest.app/post_images/sad_poop.png'"
                >
                  <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
                </v-img>

                <p class="body-1" style="padding: 5px">{{postData.content}}</p> 

             </v-card-text>

            <v-card-actions style="padding: 25px">

              <v-icon class="mr-1" color=red @click="likePost(postData)">
                mdi-heart
              </v-icon>
              <span class="subheading mr-2">{{postData.voteCount}}</span>

              <span class="mr-1">·</span>

              <v-icon class="mr-1" color=blue @click="copyURL(postData)">>
                mdi-share-variant
              </v-icon>
              <!-- <span class="subheading">{{postData.shareCount}}</span> -->


            </v-card-actions>
 
         </v-card>
      </v-sheet>
    </v-col>
    </v-row>
   </div>
</template>

<script>
import dataService from '../services/dataService.js';
export default {
    name: 'viewPost',
    props: [''], //should be in the querystring. viewPost/xxxxx/hyphenated-description-of-the-post-title
    components: { },
    data: () => ({
       postData: {}
    }),
    methods: {
        async loadPost(postId){
             try {
                
                this.postData = await dataService.loadPost(postId)
                let desc = await this.swapSpacesWithHyphens(this.postData.title);
                this.postData.link = (`https://flakest.app/#/p/${this.postData.id}/d/${desc}`);


            } catch (error) {
                this.$emit('showProblem',error);
                console.error(error);
                // Handle any errors that occur during the fetch
            }
        },
    async likePost(postObj){
         try {
              let newVoteCount = await dataService.likePost(postObj.id)            
              this.postData.voteCount = newVoteCount.voteCount;
            } catch (error) {
              this.$emit('showProblem',error);
                console.error(error);
            }
    },
    async copyURL(post) {
      console.log(post);
      try {
          await navigator.clipboard.writeText(post.link);
          // console.log('Copied to clipboard!');
          this.$emit('showNotification','Copied URL to clipboard. :)');
      } catch (error) {
          console.error('Failed to copy: ', error);
          this.$emit('showProblem',error);
      }
    },
    async swapSpacesWithHyphens(str) {
      return str.replace(/\s+/g, '-');
    }
    },

    async mounted() {
        console.log(`viewPost id: ${this.$route.params.postId}`);
        await this.loadPost(this.$route.params.postId)
    }
}
</script>

<style>
.post {
  margin-top: 75px;
  margin-bottom: 20px; 
  background-color: #f5f5f5
}
</style>