import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import viewPost from '../views/viewPost.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/p/:postId/d/:postTitle',
    name: 'viewPost',
    component: viewPost
  },

]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
