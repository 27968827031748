<template>
  <div class="home" v-scroll="onScroll">

      <v-main class="grey lighten-3" >
      <v-container class="bg-surface-variant">
        <v-row>
       
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="4"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
             
            >
       
              <v-card class="welcome" style="background-color: #f5f5f5">
                <v-card-title>
                Welcome to Flakest!💩
                </v-card-title>
                <v-card-text>
                <p>☝️ Remember all posts are satricial and generated by AI.<br/><br/>
                Prompts are generated by the flakest bot and by users, as such please report any posts which may be deemed inappropriate.<br/><br/>
                Have fun & enjoy! <br/><br/>
                ❤️ FlakeyDev</p>
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            lg="8"
            md="8"
            sm="8"
          >
            <v-sheet
              min-height="70vh" 
              rounded="lg"
            >
                <postCard @showProblem="showProblem" @showNotification="showNotification" />
            </v-sheet>
          </v-col>

                  
        </v-row>
      </v-container>
    </v-main>

    

     <v-snackbar v-model="problem" :multi-line="true" > 
      <span v-html="problemText"></span>
        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="problem = false">
            Close
            </v-btn>
        </template>
    </v-snackbar>  

    <v-snackbar v-model="notification" :multi-line="true" > 
      <span v-html="notificationText"></span>
        <template v-slot:action="{ attrs }">
            <v-btn color="green" text v-bind="attrs" @click="problem = false">
            Close
            </v-btn>
        </template>
    </v-snackbar> 
    
  </div>
</template>

<script>
import postCard from '@/components/postCard.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
      postCard
  },
  computed: {
      ...mapGetters([
      'formsScrollPos'
      ]),
  },
   data () {
    return {
        problem: false,
        problemText: '',
        notification: false,
        notificationText: ''
    }
  },
  methods: {
    onScroll() {
            if(window.scrollY>0){       //some reason fires onload at pos 0    
            this.$store.state.formsScrollPos = window.scrollY;        
            }   
    },
    showProblem(err){
      console.log(err);
      this.problem = true;
      this.problemText = `(!) ${err}`;
    },
    showNotification(note){
      console.log(note)
      this.notification = true;
      this.notificationText = note;
    }
  },
  async mounted() {
       if(this.formsScrollPos){  //return to scroll pos.
                console.log(`Returning to scroll Pos: ${this.formsScrollPos}`)
                    window.scrollTo({
                        top: this.formsScrollPos,
                        left: 0,
                        behavior: 'smooth'
                    })
                }  
  }
}

</script>
